.dark-blue {
  color: $darkblue;
}

.dark-gray {
  color: $darkgray;
}

.light-blue {
  color: $lightblue;
}

.orange {
  color: $orange;
}

.text-red {
  color: $red;
}


.strong {
  font-weight: bold;
}

.strikethrough {
  text-decoration: line-through;
}

.primary-dark {
  color: $primary-dark
}

.large-text-1 {
  font-size: 2.625rem;
  line-height: 3.676875rem;
}

.large-text-2 {
  font-size: 2.75rem;
  line-height: 3.851875rem;
}

.large-text-3 {
  font-size: 2.875rem;
  line-height: 3.509375rem;
}

.medium-text-1 {
  font-size: 1.625rem;
  line-height: 1.98375rem;
}

.medium-text-2 {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.small-text-1 {
  font-size: 1.03125rem;
  line-height: 1.65rem;
}

.small-text-2 {
  font-size: 1.125rem;
}

.small-text-3 {
  font-size: 1.25rem;
}

.small-text-4 {
  font-size: 1.375rem;
}

.small-text-5 {
  font-size: 1.5rem;
}

.tiny-text-1 {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .large-text-1 {
    font-size: 1.275rem;
    line-height: 1.375rem;
  }

  .large-text-2 {
    font-size: 1.875rem;
    line-height: 2.0rem;
  }
  
  .large-text-3 {
    font-size: 2.0rem;
    line-height: 2.2rem;
  }

  .medium-text-1 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .small-text-3 {
    font-size: 1.0rem;
  }

  .small-text-4 {
    line-height: 1.5rem;
  }

  .small-text-5 {
    line-height: 1.5rem;
  }
}