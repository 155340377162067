body {
  font-family: Calibri, Arial, sans-serif;
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
}

.main-nav {
  position: fixed;
  top: 0; left: 0;

}

.header-logo {
  width: 20rem;

  img {
    width: 100%;
  }
}

.btn-cta {
  font-weight: 700;
  color: $white !important;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  box-shadow: 0 0.2rem 0.2rem 0rem $lightgray;
  box-sizing: border-box;
  background-color: $orange;
}

.btn-activate {
  line-height: 1.525rem;
  font-size: 1.25rem;
}

.menu-item {
  font-size: 1.375rem;
  font-weight: 400;
  color: $primary-dark !important;
  text-decoration: none;
}

.mobile-activate-menu-item {
  background-color: $orange;
  color: $white;
  font-weight: bold;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem
}

.rounded-box {
  border-radius: 1rem;
  padding: 1.0rem 2.0rem;
  background-color: $white;
}

input[name="collectionDate"] {
  display: none;
}

.error {
  color: $red;
}


.unset-line-height {
  line-height: unset !important;
}

@media screen and (max-width: 575px) {
  .header-logo {
    width: 15rem;
  }
}