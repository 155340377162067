div#questionnaire {
  background: $midskyblue;
  border-radius: 10px;

  .title, .steps, .questionnaire-title, .questionnaire-steps {
    color: $heavyskyblue;
  }

  .btn {
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
  }

  select:read-only, input[type='input']:read-only {
    background: $white;
    border: none;
  }

  input[type='checkbox'] {
    //border: unset;
  }
}

.stage-bar-container {

  background-color: $white;
  --bs-gutter-x: 0 !important;
  border-radius: 2.5em;
  color: $gray;

  div.stage-bar {
    padding: 0.5rem 1.0rem 0.5rem 0.5rem;
  }

  div.checked {
    color: $white;
    background-color: $primary;
    background-image: url('~images/green-check.png');
    background-position-y: center;
    background-repeat: no-repeat;
    background-position-x: right;
  }

  div.left-rounded {
    padding-left: 0;
    border-top-left-radius: 2.0rem;
    border-bottom-left-radius: 2.0rem;
  }

  div.right-rounded {
    padding-right: 2.0rem;
    border-top-right-radius: 2.0rem;
    border-bottom-right-radius: 2.0rem;
    background-position-x: 95%;
  }



}
