.footer {
  background-color: $darkblue;
  
  p {
    color: $white;
    opacity: 0.7;
  }
  
  .footer-img img {
      width: 100%;
  }

  .footer-map img {
      border-radius: 2rem;
      width: 100%;
  }

  .col, .col-auto {
    color: $white;
  }

  .footer-link-header {
    font-size: 1.875rem;
    line-height: 2.28875rem;
  }

  .footer-link-item {
    font-size: 1.125rem;
    line-height:1.373125rem;
    margin: 1rem 0;
    
    a {
      color: $white;
      text-decoration: none;
    }
  }

  .footer-all-rights{
    font-size: 0.9375rem;
    line-height:1.33125rem;
  }

}
