$primary: #458ff6;
$secondary: #e45829;
$orange: #F26231;
$white: #ffffff;
$lightOrange:#dfbbb0;
$black: #000000;
$darkgray: #535F70;
$red: #dc3545;

// blues
$darkblue: #03295f;
$lightblue: #458ff6;
$lighterblue: #f1f7ff;
$skyblue: #d0e2fc;
$lightskyblue: #e4eefd;
$midskyblue: #EBF5FF;
$heavyskyblue: #2F80ED;

$gray: #535f70;
$lightgray: #b8b8b8;
$primary-dark: #03295f;
$off-white: #F5F9FF;
$breakpoint-small: 768px;